import React from "react"
import PropTypes from "prop-types";
import Layout from "../layouts/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo/seo"
import theme from "../theme/theme.yaml"

const footerTemplate = props => {
  const {
    data: {
      page: {
        html,
        //fields: { slug },
        frontmatter: { title },
      },
    },
  } = props

  return (
    <React.Fragment>
      <Layout>
        <SEO title={title} />
        <div className="footerSite">
          <header>
            <h1>{title}</h1>
          </header>
          <div
            className="FooterBodytext"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Layout>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footerSite {
          padding: ${`calc(${theme.space.default} * 2) ${theme.space.default} calc(${theme.space.default} * 2)`};
          position: relative;
          background-color: ${theme.color.background.white_opac};
          z-index: 0;
          width: 95vw;
          max-width: ${`calc(${theme.text.maxWidth.desktop} *2)`};
          margin: ${`calc(${theme.header.height.homepage} + ${theme.space.xl})`} auto ${theme.space.xl};
          
        }

        h1 {
          font-size: 2.5rem;
        }

        .FooterBodytext {
          animation-name: FooterBodytextEntry;
          animation-duration: ${theme.time.duration.long};
          text-align: justify;
        }

        .FooterBodytext :global(h2),
        .FooterBodytext :global(h3) {
          margin: 1.5em 0 1em;
        }

        .FooterBodytext :global(h2) {
          line-height: ${theme.font.lineHeight.s};
          font-size: ${theme.font.size.l};
        }

        .FooterBodytext :global(h3) {
          font-size: ${theme.font.size.m};
          line-height: ${theme.font.lineHeight.m};
        }

        @keyframes FooterBodytextEntry {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  )
}

export default footerTemplate

footerTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export const postQuery = graphql`
  query PageBySlug($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
